<template>
  <div class="swrapper">
    <div class="swrapper-nav">
      <!-- <div class="post-bg" :style="{ background: backgroundImg }"></div> -->
      <Swiper :options="swiperOption" ref="mySwiper">
        <Swiper-slide v-for="(item, index) in list" :key="index">
          <div class="poster" :class="{ active: currentIndex === index }">
            <img :src=" item.teamPersonalImg && item.teamPersonalImg.length > 0
                    ? item.teamPersonalImg[0].url
                    : ''" alt  />
          </div>
        </Swiper-slide>
      </Swiper>
    </div>
     <div class="movieInfo"  @click="navtoTeamDeail(info,currentIndex)">
      <div class="movieTitle">
        <span class="title">{{ info.teamPersonalName }}</span>
      </div>
      <p class="movie-desc">{{ info.teamPersonalIntroduction }}</p>
           <!-- <van-icon name="arrow" size="20" class="getmore" @click="navtoTeamDeail(info,currentIndex)" /> -->
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "swrapper",
  data() {
    return {
      currentIndex: 0,
      swiperOption: {
        delay: 2500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slideToClickedSlide: true,
        slidesPerView: 4,
        centeredSlides: true,
        spaceBetween: 5,
      },
    };
  },
  props: {
    list: Array,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper;
    },
    backgroundImg() {
      const imgList = this.list[this.currentIndex] || {};
      const img = imgList. teamPersonalImg&& imgList.teamPersonalImg.length>0?imgList.teamPersonalImg[0].url: "";
      return `url(${img})`;
    },
    info() {
      return this.list[this.currentIndex] || {};
    },
  },
  created(){
     this.$nextTick(()=>{
       let This=this
       this.swiper.$on('slideChangeTransitionStart',function(){
        This.currentIndex=this.$swiper.activeIndex
       })
     })
  },
  methods:{
         //点击跳转到团队详情页
         navtoTeamDeail(item,inedx){
                // console.log(item)
                this.$router.push({path:'/McompanyTeam',query:{data:JSON.stringify(item),index:inedx}})
         }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
<style lang="scss" scoped>
@import "~swiper/swiper-bundle.css";
.swrapper-nav {
  position: relative;
  padding: 30px 15px 30px 5px;
  // transform: translateZ(0);
  overflow: hidden;
  .post-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // z-index: -1;
    -webkit-filter: blur(30px);
    filter: blur(30px);
  }
  .poster {
    padding-bottom: 4px;
    width: 65px;
    transition: transform 0.3s;
    position: relative;
    &.active {
      position: relative;
      width: 65px;
      height: 94px;
      border: 2px solid #fff;
      transform: scale(1.3);
      &::before{
        position: absolute;
        content:'';
        left:50%;
        bottom:-11px;
        //使其在正中间
        transform: translateX(-50%);
        border:5px solid transparent;
        border-top:5px solid #fff;

      }
  }
    img {
      width: 100%;
      height: 96px;
      transform: scale(1);
      vertical-align: middle;
    }
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    width: 65px;
    height: 94px;
  }
   
}
.movieInfo{
  padding:11px 25px;
  text-align: center;
  position: relative;
  background-color: #fff;
  .movieTitle{
     height:24px;
     line-height: 24px;
     font-size:16px;
     color:#222;
     font-weight:700;
     .title{
       line-height: 24px;
     font-size:17px;
     color:#333;
     font-weight:700;
     }
  }
   .movie-desc{
     margin:0;
     margin-top:5px;
     overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /*3表示只显示3行*/
      -webkit-box-orient: vertical;
     line-height: 18px;
     color:#777;
     font-size:12px;
   }
   .getmore{
       position: absolute;
       top:50%;
       transform: translateY(-50%);
       right:2px;
   }
}
</style>