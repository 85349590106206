<template>
  <!-- headerbar部分 -->
  <div class="img">
    <img :src="imgs" alt="" />
    <div class="title">
      <commonT :value="titleConent" class="commonT" />
    </div>
  </div>
</template>
<script>
export default {
    name:'ComHeader',
    props:{
        imgs:{
            type:String,
            default(){
                return ''
            }
        },
        titleConent:{
             type:Object,
            default(){
                return {}
            }
        }
    }
}
</script>
<style lang="scss" scoped>
 .img {
    width: 100%;
    height: 165px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .title {
      width: 80%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 64px;
      box-sizing: border-box;
      background-color: #fff;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: #ddd;
      }

      .commonT {
        width: 100%;
        padding-left: 20px;
      }
    }
 }
</style>