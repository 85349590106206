<template>
  <div class="FooterContainer">
    <div class="qurod">
      <img
        :src="
          companyInfo.webWechatQrcode && companyInfo.webWechatQrcode.length > 0
            ? companyInfo.webWechatQrcode[0].url
            : ''
        "
        alt=""
      />
    </div>
    <div class="navtitle">
      <ul>
        <li v-for="item in itemlist" :key="item.id" @click="$router.push(item.url)">{{ item.title }}</li>
      </ul>
    </div>
    <!-- <div class="Info"> -->
    <div class="comInfo">
      <h4 class="h">{{ companyInfo.webCorporateName }}</h4>
      <p class="addr">{{ companyInfo.webCorporateAddress }}</p>
      <p>电话：{{ companyInfo.webCorporatePhone }}</p>
      <p>邮箱：{{ companyInfo.webCorporateEmail }}</p>
      <p class="alink">
        <img src="../../../assets/wangan.png" alt="" />
        <a
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          target="_blank"
          >{{ companyInfo.webCorporateRecord }}</a
        >
      </p>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  name: "FooterContainer",
  data() {
    return {
      companyInfo: {},
      itemlist: [
        { id: 1, 
        title: "公司首页" ,
        url:'/',
        hidden:true,
        },
        {
          id: 2,
          title: "产品服务",
          url:'/Mproducts',
          hidden:true,
        },
        {
          id: 3,
          title: "品牌服务",
           url:'/MBrand',
           hidden:true,
        },
        {
          id: 4,
          title: "公司团队",
           url:'/McompanyTeam',
           hidden:true,
        },
        {
          id: 5,
          title: "新闻动态",
            url:'/Mnews',
            hidden:true,
        },
        {
          id: 6,
          title: "关于我们",
          url:'/McontactUS',
          hidden:true,
        },
      ],
    };
  },
  created() {
    this.getCompanyInfo();
    this.getbrandswitch()
  },
  methods: {
    async getCompanyInfo() {
      let res = await this.$api.getCompanyInfo();
      if (res.code == 0) {
        if (res.data.webCorporateImage) {
          res.data.webCorporateImage = JSON.parse(res.data.webCorporateImage);
          console.log(res.data.webCorporateImage);
        }
        if (res.data.webLog) {
          res.data.webLog = JSON.parse(res.data.webLog);
        }
        if (res.data.webWechatQrcode) {
          res.data.webWechatQrcode = JSON.parse(res.data.webWechatQrcode);
        }
        console.log(res.data);
        this.companyInfo = res.data;
      }
    },
    async getbrandswitch(){
      let result=await this.$api.getbrandswitch();
       if(result.code==0){
          let switchValue=Number(result.data.switchs)?true:false
          this.itemlist[2].hidden=switchValue
       }
       let arr=[]
        this.itemlist.forEach(item=>{
          if(item.hidden){
            arr.push(item)
          }
        })
        console.log(arr)
        this.itemlist=arr
    },
  },
};
</script>
<style lang="scss" scoped>
.FooterContainer {
  width: 100%;
  height: 320px;
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 20px 20px;
  .qurod {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .navtitle {
    margin-top: 10px;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 5px 0;
        padding: 0 5px;
        font-size: 12px;
        color: #999;
        & + li {
          border-left: 1px solid #a8a8a8;
        }
      }
    }
  }
}
.comInfo {
  text-align: left;
  h4 {
    color: rgba(0, 0, 0, 0.65);
    font-size: 17px;
    margin: 10px 0;
    text-align: center;
  }
  p {
    margin: 0;
    margin-bottom: 8px;
    padding-left:50px;
    // text-align:center;
    font-size: 13px;
    color: #999;
    &.addr {
      font-size: 12px;
    }
    &.alink {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 3px;
      }
      a {
        text-decoration: none;
        color: #999;
        cursor: pointer;
      }
    }
  }
}
</style>