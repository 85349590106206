<template>
  <div class="title">
    <p style="margin: 0; text-align: left">{{ value.name }}</p>
    <p class="secondP">
      {{ value.ename }}
    </p>
  </div>
</template>
<script>
export default {
  name: "commonT",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.secondP{
      color: #3333; 
      text-align: left;
      margin: 3px 0 0 0;
      font-size: 13px;
      font-weight:bold;
}
  
</style>