import { BaseData } from './base.js'
//创建一个子类继承父类
class API extends BaseData {
     //获取轮播图列表
     getBannerList() {
          return this.get('/webBanner/list')
     }

     //获取产品类别
     getproductCategroy() {
          return this.get('webSeries/list')
     }
     //获取产品列表
     getProductList(seriesId, pageSize, pageIndex) {
          return this.get(`webProduct/list?seriesId=${seriesId}&pageSize=${pageSize}&pageIndex=${pageIndex}`)
     }
     //获取公司资料
     getCompanyInfo() {
          return this.get(`webData/list`)
     }
     //获取公司团队信息
     getCompanyTeam(pageIndex, pageSize) {
          return this.get(`webTeam/list?pageIndex=${pageIndex}&pageSize=${pageSize}`)
     }
     //    获取公司新闻类别
     getnewscategroy() {
          return this.get(`webNewsType/list`)
     }
     //获取公司新闻信息列表
     getnewsList(newsTypeId, pageIndex, pageSize) {
          return this.get(`webNews/list?newsTypeId=${newsTypeId}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
     }
     //提交用户留言信息到后台
     sendMessage(data) {
          return this.post(`webLeavingMsg/`, data)
     }
     //获取公司优势信息
     getAdvantageList() {
          return this.get(`webAdvantage/list`)
     }
     //获取品牌列表
     getBrandList(pageIndex, pageSize) {
          return this.get(`webBrand/list?pageIndex=${pageIndex}&pageSize=${pageSize}`)
     }
     //获取品牌开关权限
     getbrandswitch(){
          return this.get(`webBrandSwitch/`)
     }
}
//导出这个类的实例
export default new API()