import Vue from 'vue'
//自动将common里面的vue文件注册全局组件
const requireContext=require.context('./',true,/.vue$/)
console.log(requireContext.keys())
requireContext.keys().forEach(fileName =>{
    //获取组件配置
    const componentConfig = requireContext(fileName)
    console.log( componentConfig.default.name,componentConfig.default)
    Vue.component(
        componentConfig.default.name,
        componentConfig.default
        )
})