import request from './request'

//创建一个类
export  class BaseData{
   //在constructor里面添加私有属性handleFn
   constructor(){
       this.handleFn=request
   }
   responseData(res){
       const {data,status}=res
       if(status==200) return data   //如果状态码为200这返回data
       return res                    //否则返回res
   } 
   get(url,data={}){
        return this.handleFn.get(url,data).then(this.responseData)
   }
   post(url,data={},config){
    return this.handleFn.post(url,data,config).then(this.responseData)
   }
   put(url,data={}){
    return this.handleFn.put(url,data).then(this.responseData)
   }
   delete(url,data={}){
    return this.handleFn.delete(url,data).then(this.responseData)
   }
}