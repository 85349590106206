import Vue from "vue";
import VueRouter from "vue-router";
import Home from "views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect:'/homeContent',
    children:[
      {
        path:'/homeContent',
        name:'homeContent',
        component: () => import( "views/homeContent"),
      },
      {
        path:'/MBrand',
        name:'M-Brand',
        component: () => import( "views/M-Brand"),
      },
      {
        path:'/McompanyTeam',
        name:'M-companyTeam',
        component: () => import( "views/M-companyTeam"),
      },
      {
        path:'/McontactUS',
        name:'M-contactUS',
        component: () => import( "views/M-contactUS"),
      },
      {
        path:'/Mproducts',
        name:'M-products',
        component: () => import( "views/M-products"),
      },
      {
        path:'/Mnews',
        name:'Mnews',
        component: () => import( "views/Mnews"),
      },
    ]
  },
 
  {
    path:'*',
    name:'404',
    component: () => import( "views/404.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
