<template>
  <div class="wrap_container" :class="Isactive ? 'active' : ''">
    <!-- <van-sticky :offset-top="0"> -->
      <div class="wrap">
        <div class="menu_wrap" @click="showPop">
          <img :src="isShow?imgs[1].url:imgs[0].url" alt="" />
        </div>
        <div class="Header_title">
          <img src="../../assets/logo.png" alt="" />
          <p>私脸定治</p>
        </div>
        <div class="right"></div>
      </div>
    <!-- </van-sticky> -->
  </div>
</template>
<script>
export default {
  name: "headerWrap", //头部
  data() {
    return {
      isShow: false,
      imgs: [
        {
          id: 1,
          url: require("../../assets/menu.svg"),
        },
        {
          id: 2,
          url: require("../../assets/closed.svg"),
        },
      ],
    };
  },
  props: {
    Isactive: {
      type: Boolean,
      default() {
        return false;
      },
    },
    popIconShow:{
      type:Boolean,
      default(){
        return false
      }
    }
  },
  methods: {
    //显示内容导航弹窗
    showPop() {
      // this.isShow=this.popIconShow
      this.isShow = !this.isShow;
      this.$emit("changeShow", this.isShow);
    },
  },
  watch:{
      popIconShow(value){
        //  console.log(value)
         this.isShow=value
      }
  }
};
</script>
<style lang="scss" scoped>
.wrap_container {
  width: 100%;
  height: 40px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  div {
    width: 100%;
    height: 100%;
  }
  // & /deep/ .van-sticky {
  //   width: 100%;
  //   height: 100%;
    .wrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .menu_wrap {
        width: 60px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          color: #eee;
          background-color: rgb(134, 91, 134);
          width: 30px;
          height: 30px;
        }
      }
      .Header_title {
        flex: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        p {
          margin: 0;
          margin-left: 5px;
          color: #A6D7E5;
          font-size: 16px;
        }
      }
      .right {
        width: 50px;
      }
    }
  }
// }
.active {
  background-color: rgba(0, 0, 0, 0.62);
}
</style>