<template>
  <div class="commonNav">
    <div v-for="item in NavList" :key="item.id" class="p-item" :class="{active:item.id==currentId}" @click="changeCategroyIndex(item)">
      {{ item.seriesName||item.newsTypeName}}
    </div>
  </div>
</template> 
<script>
export default {
  name: "commonNav",
  data() {
    return {
        currentId:1,
    };
  },
  props: {
    NavList: {
      type: Array,
      default() {
        return [];
      },
    },
    serieId:{
      type:String,
      default(){
         return ''
      }
    }
  },
  methods:{
     changeCategroyIndex(item){
          this.currentId=item.id
          this.$emit("changeserrires",item.id)
     }
  },
  watch:{
      NavList(newV){
        if(newV){
          console.log(newV)
            this.currentId=newV[0].id
        }
      },
      serieId(newvalue){
        console.log(newvalue)
        this.currentId=newvalue
      }
  }
};
</script>
<style lang="scss" >
.commonNav {
  // height: 100%;
  margin-top:10px;
  width:70px !important;
  height:40px !important;
  .p-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 14px;
    // width:100%;
    height:100%;
    // width: 100%;
    // width: 80px;
    // height:40px;
    font-size: 14px;
    color: #606266;
    position: relative;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    &.active {
      color: #57b6d0;
      background: #f8f8f8;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top:50%;
        transform: translateY(-50%);
        height: 17px;
        width: 5px;
        background-color: #57b6d0;
        // border-radius: 0 4px 4px 0;
        opacity: 0.8;
      }
    }
  }
}
</style>