<template>
  <div class="home">
      <headerWrap  @changeShow="showPop" :Isactive="Isactive" :popIconShow="popShow"/>
    <div class="main">
        <router-view></router-view>
    </div>
    <!-- 导航区 -->
    <div class="popContent">
         <van-popup v-model="popShow" position="left" :style="{ height:'100%',width:'40%'}" @click-overlay="closedShow" :closeable="bol">
             <div class="navBar">
                 <ul>
                    <router-link  v-for="(item,index) in lists"  tag="li" :to="item.ToUrl" :key="item.id" :class="current==index?'active':''" @click.native="popShow=false;current=index">{{item.name}}</router-link>
                 </ul>
             </div>
         </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      Isactive:false,
      popShow:false,
      current:0,
      bol:true,
      lists:[
        {
          name:'网站首页',
          ToUrl:'/',
          id:1,
          hidden:true,
        },
          {
          name:'产品服务',
          ToUrl:'/Mproducts',
           hidden:true,
          id:2
        },
          {
          name:'品牌介绍',
          ToUrl:'/MBrand',
           hidden:true,
          id:3
        },
          {
          name:'团队介绍',
          ToUrl:'/McompanyTeam',
          hidden:true,
          id:4,
        },  {
          name:'新闻动态',
          ToUrl:'/Mnews',
          hidden:true,
          id:5
        },
          {
          name:'联系我们',
          ToUrl:'/McontactUS',
          hidden:true,
          id:6
        }
      ]
    };
  },
  mounted () {
  window.addEventListener('scroll', this.handleScroll)
  this.getbrandswitch()
  },
  methods:{
    showPop(bol){
       console.log(bol)
       this.popShow=bol
    },
    handleScroll(){
       var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
       if(scrollTop>10){
         this.Isactive=true
         return
       }
        this.Isactive=false
      
    },
    async getbrandswitch(){
      let result=await this.$api.getbrandswitch();
       if(result.code==0){
          let switchValue=Number(result.data.switchs)?true:false
          this.lists[2].hidden=switchValue
       }
       let arr=[]
        this.lists.forEach(item=>{
          if(item.hidden){
            arr.push(item)
          }
        })
        console.log(arr)
        this.lists=arr
    },
    closedShow(){
        this.popShow=false
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
}
.banner{
   background-color: #ddd;
}
.navBar{
  margin-top:100px;
  ul{
    li{
      height:40px;
      line-height:40px;
      font-size:16px;
      border-bottom: 1px dotted #ddd;
       &+li{
         margin-top:10px;
       }
    }
  }
}
li.active{
  position: relative;
    color: #57b6d0;
     &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        width: 4px;
        background-color: #57b6d0;
        opacity: 0.8;
      }
}
</style>
