<template>
    <div class="categroyTitle">
        <p>{{titleContent.name}}</p>
        <p>{{titleContent.ename}}</p>
    </div>
</template>
<script>
export default {
    name:'commonTitle',
    props:{
        titleContent:{
            type:Object,
            default(){
                return {}
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.categroyTitle{
    height:60px;
}
</style>