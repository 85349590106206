import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from 'vant';
import{Lazyload} from 'vant';
import 'vant/lib/index.css';
import api from './api/index.js'
import "lib-flexible/flexible"
import 'components/common'
import FastClick from "fastclick"   //解决在移动端点击延时30毫秒问题
FastClick.attach(document.body)  
Vue.use(Vant);
Vue.use(Lazyload)
Vue.prototype.$api=api
Vue.config.productionTip = false;

//动态设置title、meta标签内容
!async function setMetaValue(){
  let res=await api.getCompanyInfo()
  document.title = res.data.webTitle || '时光逸美'
  console.log( document.querySelector('meta[name="keywords"]'),document.querySelector('meta[name="description"]').setAttribute('content', res.data.webDescription))
  document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.webKeywords) 
  document.querySelector('meta[name="description"]').setAttribute('content', res.data.webDescription)
}()

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
